import React from 'react';
import Layout from '../components/Layout';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const About = () => {
    return (
        <Layout>
            <Paper sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                    About Subgraph Generator
                </Typography>
                <Typography variant="body1">
                    Subgraph Generator is a tool to help developers easily create subgraphs for their decentralized applications. Simply input your contract address and desired subgraph name, and let the generator do the rest!
                </Typography>
            </Paper>
        </Layout>
    );
};

export default About;
