import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, CircularProgress, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';

const SubgraphForm = () => {
    const [contractAddress, setContractAddress] = useState('');
    const [subgraphName, setSubgraphName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');

        if (!contractAddress || !subgraphName) {
            setError('Please fill in all fields');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post('http://localhost:5000/api/create-subgraph', {
                subgraphName,
                contractAddress,
            });

            if (response.data.success) {
                setSuccessMessage('Subgraph created successfully!');
            } else {
                setError('Failed to create subgraph. Please try again.');
            }
        } catch (err) {
            setError('Error connecting to the backend. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper sx={{ p: 4 }}>
            <Typography variant="h6" gutterBottom>
                Create a New Subgraph
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Subgraph Name"
                            fullWidth
                            value={subgraphName}
                            onChange={(e) => setSubgraphName(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="Enter a unique name for your subgraph">
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Contract Address"
                            fullWidth
                            value={contractAddress}
                            onChange={(e) => setContractAddress(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="Enter the Ethereum contract address">
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Typography color="error">{error}</Typography>
                        </Grid>
                    )}
                    {successMessage && (
                        <Grid item xs={12}>
                            <Typography color="success.main">{successMessage}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Generate Subgraph'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default SubgraphForm;
