import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 3 }} component="footer">
            <Typography variant="body1">
                Subgraph Generator © {new Date().getFullYear()}
            </Typography>
        </Box>
    );
};

export default Footer;
